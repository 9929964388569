import React, { useState } from 'react'
import Footer from '../components/footer'
import '../styles/components/what-is-new-category.less'
import '../styles/what-is-new-article.less'
import SEO from '../components/SEO'
// import { graphql  from 'gatsby'
import Header from '../components/header'
import { Category } from '../pages-en/what-is-new'
const loadText = require('src/utils').loadText
const data = loadText('what-is-new')
import dayjs from 'dayjs'

export default function Home({ pageContext }) {
  // console.log(pageContext)
  const { allCategories, content, title, created_at } = pageContext
  return (
    <div className="what-is-new-article">
      <SEO title={`${title} | Zenlayer`} description={title} />
      <div className="what-new-article">
        <div className="bg"></div>
        <div className="headerContainer">
          <Header logo="/logo-blue.svg" />
        </div>
        <div className="content">
          <div className="left">
            <Category categories={allCategories} />
          </div>
          <div className="article">
            <div className="title">{title}</div>
            <div className="date">{`Posted On: ${dayjs(created_at).format(
              'MMM DD, YYYY'
            )}`}</div>
            <div
              className="article-content"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
