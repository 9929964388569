import React, { useState, useMemo } from 'react'
import Footer from '../components/footer'
import '../styles/what-is-new.less'
import '../styles/components/what-is-new-category.less'
import SEO from '../components/SEO'
import Header from '../components/header'
import { graphql, navigate, navigateTo } from 'gatsby'
import { Pagination, Button } from '../components/ui'
import { parse } from 'query-string'
// import fuzzysort from 'fuzzysort'
import dayjs from 'dayjs'
const loadText = require('src/utils').loadText
const data = loadText('what-is-new')
const TopBanner = () => {
  return (
    <div className="topBanner">
      <div className="topBanner-bg">
        <div className="headerContainer">
          <Header logo="/logo-blue.svg" />
        </div>
        <div className="content">
          <div className="left">
            <div className="title">{data.topBanner.title}</div>
            <div className="description">{data.topBanner.description}</div>
          </div>
          <img src="/what-is-new/topBanner-pic.png" alt="" />
        </div>
      </div>
    </div>
  )
}
const Search = ({
  value,
  onChange,
}: {
  value: string
  onChange: (val: string) => void
}) => {
  const [focused, setFocus] = useState(false)
  return (
    <div className={`post-search ${focused ? 'focused' : ''}`}>
      <img src="/what-is-new/search.svg" alt="search" className="search-icon" />
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={data.subject.searchPlaceholder}
        onFocus={() => {
          setFocus(true)
        }}
        onBlur={() => {
          setFocus(false)
        }}
      />
      {value && (
        <div
          className="close-icon"
          onClick={() => {
            onChange('')
          }}
        />
      )}
    </div>
  )
}
export const Category = ({
  categories,
  active,
}: {
  categories: { name: string }[]
  active?: string
}) => {
  const [expanded, setExpanded] = useState(false)
  const _categories = [{ name: '' }, ...categories]
  return (
    <div className="left-category">
      <div className="btn-row only-mobile">
        <Button
          width={544}
          size="l"
          onClick={() => {
            setExpanded((expanded) => !expanded)
          }}
        >
          {data.subject.categoryTitle}
          <img
            src={`/what-is-new/${expanded ? 'up' : 'down'}.svg`}
            alt="icon"
          />
        </Button>
        {expanded && (
          <div className="category">
            {_categories.map(({ name }) => (
              <div
                className={`item ${active === name ? 'active' : ''} `}
                onClick={() => {
                  navigateTo(`/what-is-new/?category=${name}`)
                }}
              >
                {name || 'View All'}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="categoryList only-desktop">
        <img
          src="/what-is-new/category.svg"
          alt="category"
          onClick={() => {
            navigateTo(`/what-is-new/`)
          }}
        />
        <div className="title">{data.subject.categoryTitle}</div>
        <div className="category">
          {_categories.map(({ name }) => (
            <div
              className={`item ${active === name ? 'active' : ''} `}
              onClick={() => {
                navigateTo(`/what-is-new/?category=${name}`)
              }}
            >
              {name || 'View All'}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const Content = ({ categories, articles, category }) => {
  const [query, setQuery] = useState('')
  const filtered = useMemo(() => {
    return articles.filter((a) => a.title.indexOf(query) > -1)
  }, [articles, query])
  const total = useMemo(() => {
    return filtered.length
  }, [filtered])
  const pageSize = 10
  const [pageNum, setPageNum] = useState(1)
  const start = (pageNum - 1) * pageSize
  return (
    <div className="main-content">
      <div className="left">
        <Category categories={categories} active={category || ''} />
      </div>
      <div className="postList">
        <Search value={query} onChange={setQuery} />
        <div className="postContent">
          {filtered
            .slice(start, start + pageSize)
            .map(({ title, created_at, slug }) => (
              <div className="item">
                <div
                  className="name"
                  onClick={() => {
                    navigateTo(`/what-is-new/${slug}/`)
                  }}
                >
                  {title}
                </div>
                <div className="date">
                  {dayjs(created_at).format('MM/DD/YYYY')}
                </div>
              </div>
            ))}
          {filtered.length === 0 && (
            <div className="noData">{data.subject.noData}</div>
          )}
        </div>
        {total > 10 && (
          <div className="pagination">
            <Pagination
              pageNum={pageNum}
              total={total}
              pageSize={pageSize}
              onChange={(page) => {
                setPageNum(page)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default function Home({ data, location }) {
  const { allStrapiWhatsNewPosts, allStrapiWhatsNewTypes } = data
  const category = parse(location.search).category || ''
  return (
    <div className="what-is-new">
      <SEO {...data.seo} />
      <TopBanner />
      <Content
        category={category}
        categories={allStrapiWhatsNewTypes.nodes}
        articles={
          category
            ? allStrapiWhatsNewPosts.nodes.filter(
                (a) => (a.whats_new_type || {}).name === category
              )
            : allStrapiWhatsNewPosts.nodes
        }
      />

      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiWhatsNewPosts(sort: { fields: created_at, order: DESC }) {
      nodes {
        title
        whats_new_type {
          name
        }
        slug
        created_at
      }
    }
    allStrapiWhatsNewTypes {
      nodes {
        id
        name
      }
    }
  }
`
